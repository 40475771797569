import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { a } from 'aws-amplify'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.div`
  position: fixed;
  bottom: 100px;
  left: 30px;
  width: 300px;
  height: 250px;
  z-index: 100;
  img {
    height: 250px;
    width: 300px;
    border: 2px solid #e5e5e5;
  }
  .popup-close {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 29px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50%;
    border: solid 2px #e5e5e5;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
`

// ▼ここで何ピクセルスクロールしたら表示するのかを設定してね
const PAGE_Y_OFFSET = 1500

const PopupBanner = () => {
  const [show, setShow] = useState<boolean>(false)

  const changeShow = () => {
    if (window.pageYOffset > PAGE_Y_OFFSET) {
      setShow(true)
    } else {
      setShow(false)
    }
  }

  const [visible, setVisible] = useState(true)

  useEffect(() => {
    window.addEventListener('scroll', changeShow)
    return () => window.removeEventListener('scroll', changeShow)
  }, [])

  const [no, setNo] = useState(-1)
  useEffect(() => {
    setNo(Math.floor(Math.random() * 2))
  }, [])

  const routeParam = useRouteParam(
    'https://www.kintaicloud.jp/contact/?utm_source=column&utm_medium=popup&utm_campaign=popup_202301'
  )
  const routeParam2 = useRouteParam(
    'https://www.kintaicloud.jp/contact/?utm_source=column&utm_medium=popup&utm_campaign=popup_202302'
  )

  if (show)
    return (
      <Wrapper style={{ visibility: visible ? 'visible' : 'hidden' }}>
        {no === 0 && (
          <div>
            <a target="_blank" href={routeParam} rel="noreferrer">
              <img
                src="/images/column/common/popupBanner01.png"
                alt="キンクラ"
              />
            </a>
          </div>
        )}
        {no === 1 && (
          <div>
            <a target="_blank" href={routeParam2} rel="noreferrer">
              <img
                src="/images/column/common/popupBanner02.png"
                alt="キンクラ"
              />
            </a>
          </div>
        )}
        {/** ▼閉じるボタン */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <a className="popup-close" onClick={() => setVisible(!visible)}>
          ×
        </a>
      </Wrapper>
    )
  return null
}

export default PopupBanner
