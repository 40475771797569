import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: fit-content;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff7070;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }

    //  表用のCSS
    table {
      width: 100%;
      margin-bottom: 15px;


      td {
        padding: 5px;
        border: solid 1px rgba(200, 200, 200, 1);
        vertical-align: top;
      }
    }
  }

}

${BreakPoints.large} {
}
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0097 = (props: Props): JSX.Element => {
  const routeParam = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0070/'
  )
  const routeParam2 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0090/'
  )
  const routeParam3 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0067/'
  )
  const routeParam4 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0069/'
  )
  const routeParam5 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0089/'
  )
  const routeParam6 = useRouteParam('https://www.kintaicloud.jp/')

  return (
    <Wrapper>
      <ColumnH1 label="勤怠管理システムは自作できる？メリットやデメリット・注意点について解説" />
      {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
      <p className="p-txt-date">2024.05.15</p>
      <section>
        <img
          src="/images/column/details/c0097.jpg"
          alt="キンクラコラム画像97"
        />
        <p>
          勤怠管理システムを自作している事業者の方もいるのではないでしょうか。低コストというメリットはあるものの、デメリットや注意点を理解しておかなければ後悔する恐れがあります。
          <br />
          本記事では、勤怠管理システムを自作するメリットやデメリット、注意点について詳しく解説します。
        </p>
        <div id="toc1">
          <h1>勤怠管理システムは自作できるのか</h1>
        </div>
        <img
          src="/images/column/details/c0097_g01.jpg"
          alt="キンクラコラム画像97"
        />
        <p>
          勤怠管理システムを自作することは可能です。その1つにExcel(エクセル)での管理や自作アプリの活用があります。
        </p>
        <div id="toc1-1">
          <h2>Excelで自作する場合</h2>
        </div>
        <p>
          勤怠管理システムをExcelで自作する場合、以下2点のいずれかの知識が必要です。
          <br />
          ・関数を活用
          <br />
          ・マクロを活用
          <br />
          <br />
          よく使用する関数の種類には、以下が挙げられます。
        </p>
        <table>
          <thead>
            <tr>
              <th>関数</th>
              <th>できること</th>
              <th>使用場面</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>SUM関数</td>
              <td>範囲内のセルの数値を合計する</td>
              <td>
                ・1か月間の労働時間の集計
                <br />
                ・残業時間の集計
              </td>
            </tr>
            <tr>
              <td>TIME関数</td>
              <td>時刻から時間を算出する</td>
              <td>・60進数で表記された時刻から労働時間や残業時間を算出</td>
            </tr>
            <tr>
              <td>FLOOR関数</td>
              <td>指定した基準値の倍数で数値を切り捨てる</td>
              <td>・出勤時刻を15分単位・30分単位で切り捨て</td>
            </tr>
            <tr>
              <td>TEXT関数</td>
              <td>日付に対応した曜日を表示する</td>
              <td>・平日か土日・休日かなど曜日の認識</td>
            </tr>
            <tr>
              <td>IF関数</td>
              <td>特定の条件を満たしているかで返す数値を変える</td>
              <td>
                ・所定労働時間を超えている場合
                <br />
                ・深夜残業をした場合
                <br />
                ・休日に出勤した場合
                <br />
                （※割増賃金が適用される場面）
              </td>
            </tr>
            <tr>
              <td>COUNTIIF関数</td>
              <td>指定した条件を満たしたセルの数を数える</td>
              <td>・出勤日数・欠勤日数、有給休暇を取得した日数の算出</td>
            </tr>
          </tbody>
        </table>
        <p>
          マクロはVBAというプログラミング言語で作成します。そのため、作業の自動化が可能です。
        </p>
        <a href={routeParam} className="column-detail-link">
          参照記事：Excel(エクセル)でできる勤怠管理の限界とシステム導入で得られるメリットとは？
        </a>
        <div id="toc1-2">
          <h2>自作アプリの場合</h2>
        </div>
        <p>
          最近では、プログラミングの知識がなくても自社の業務フローにあったアプリが作成できます。そのシステムを利用して、自社向けの勤怠管理アプリを作成します。もちろんPHPやJavaなどの知識があれば、より高度な勤怠管理システムが作成できるでしょう。勤怠管理アプリを自作する場合には、以下の機能が必要です。{' '}
        </p>
        <table>
          <thead>
            <tr>
              <th>必要な項目</th>
              <th>できること</th>
              <th>あると便利な機能</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>打刻機能</td>
              <td>従業員の勤務形態に合わせた出退勤の管理</td>
              <td>打刻忘れのアラート機能</td>
            </tr>
            <tr>
              <td>残業や休暇などの管理機能</td>
              <td>残業時間の管理や休暇の取得状況などの把握</td>
              <td>残業過多や休暇取得などの通知機能</td>
            </tr>
            <tr>
              <td>勤務時間データの集計・出力</td>
              <td>
                総労働時間・残業時間・休日労働時間・欠勤日数・休暇取得日数などの項目ごとに集計
              </td>
              <td>CSV出力</td>
            </tr>
            <tr>
              <td>外部システムとの連携</td>
              <td>
                すでに導入している社内システム（給与計算ソフトなど）との連携
              </td>
              <td>
                複数の種類に対応していると連携先ソフトを変更しても対応できる
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          これらの機能があるアプリを自作できれば、人事労務管理だけではなく従業員の給与計算まで効率よく行えるでしょう。
          <br />
          <br />
          このように勤怠管理は、Excelや提供されているツールを使用する自作アプリの開発で自作が可能です。
        </p>
        <a href={routeParam2} className="column-detail-link">
          参照記事：無料で使える勤怠管理アプリとは？メリットや注意点も紹介
        </a>
        <div id="toc1-3">
          <h2>勤怠管理システムを自作する2つのメリット</h2>
        </div>
        <p>
          勤怠管理システムを自作することで得られるメリットは次のとおりです。
        </p>
        <h3>必要な機能だけ取り入れられる</h3>
        <p>
          自社に必要な機能だけを取り入れた勤怠管理システムができます。不要な機能は装備せず必要なものだけを取り入れることで、従業員が操作するうえでも使用しやすい勤怠管理システムができます。また、コスト効率の面でも無駄の削減につながり、メンテナンスやアップデートも自社のペースに合わせて自由に実施できるでしょう。あわせて、従業員の意見も反映しやすく、実際に使用するうえでストレスを感じにくいシステムが使用できます。
        </p>
        <a href={routeParam3} className="column-detail-link">
          参照記事：勤怠管理は経費精算付きのクラウドがおすすめ！ その理由とは...
        </a>
        <h3>ランニングコストがかからない</h3>
        <p>
          パッケージシステムの場合、ライセンス費用や更新費用が発生します。また、自社のニーズにあわせてカスタマイズすることが難しい場合もあります。カスタマイズできたとしても、追加費用が発生するケースも珍しくありません。また、自社で勤怠管理データを管理することは、クラウドストレージなどのサービス費用の削減につながります。
          <br />
          <br />
          勤怠管理システムを自作することは、自社に必要な機能だけに特化しコストを抑えて使用できます。
        </p>
        <a href={routeParam4} className="column-detail-link">
          参照記事：手動での勤怠管理からの脱却！無料の勤怠管理システムのメリットとは？
        </a>
        <div id="toc2">
          <h1>勤怠管理システムを自作するデメリットや注意点</h1>
        </div>
        <img
          src="/images/column/details/c0097_g02.jpg"
          alt="キンクラコラム画像97"
        />
        <p>
          勤怠管理システムを自作すると、最も必要な次の機能に対応できない可能性があります。
        </p>
        <div id="toc2-1">
          <h2>法改正に対応できない</h2>
        </div>
        <p>
          労働法やそれに関連する法律は、頻繁に改正が入ります。この改正に対応できない場合、企業にとって、訴訟リスクにつながるケースがあります。法改正に対応するためには、頻繁にシステム更新が必要です。更新作業には、人的コストがかかります。もし、自社の従業員が本来すべき業務のほかに勤怠管理システムの運用を行っていた場合には、本来業務に支障が出る可能性も出てくるでしょう。{' '}
        </p>
        {/** ここに9パターンの広告設定する */}
        {props.no === 0 && <Introduction004 />}
        {props.no === 1 && <Introduction005 />}
        {props.no === 2 && <Introduction006 />}
        {props.no === 3 && <Introduction007 />}
        {props.no === 4 && <Introduction008 />}
        {props.no === 5 && <Introduction009 />}
        {props.no === 6 && <Introduction010 />}
        {props.no === 7 && <Introduction011 />}
        {props.no === 8 && <Introduction012 />}
        <div id="toc2-2">
          <h2>改ざんが容易</h2>
        </div>
        <p>
          勤怠管理システムのプロが作成したものではないため、改ざんが簡単にできる可能性があります。特にExcelの場合、身近にあるソフトであり誰でも操作できるため、意図せず改ざんしてしまう可能性もあります。改ざんが容易な勤怠管理システムは、従業員の勤務状態について正しく把握できません。万が一、従業員との間で訴訟が発生した場合、ベンダーが開発した勤怠管理システムよりも証拠能力が低くなるため、企業側が不利になるケースがあります。{' '}
        </p>
        <div id="toc2-3">
          <h2>専門知識が必要</h2>
        </div>
        <p>
          法改正に対応するためには、労働法に詳しい担当者が必要です。対応漏れは、法的リスクに直結するため、専門知識がある人材を確保しなければなりません。しかし、そのような人材を確保するためには、内部研修の程度で専門知識は身につきません。外部から専門知識のある人材を確保するにもコストがかかります。また、情報漏洩が発生しないためにもセキュリティ面での専門知識も必要になるため、労務管理とシステム全般に関する知識をもつ担当者が必要です。
          <br />
          <br />
          勤怠管理システムを自作することは、事業に損害を与えかねないデメリットや注意点があります。
        </p>
        <a href={routeParam5} className="column-detail-link">
          参照記事：中小企業向け勤怠管理システムの選び方とは？おすすめシステムも紹介
        </a>
        <div id="toc3">
          <h1>勤怠管理システムを自作することにはリスクがある</h1>
        </div>
        <p>
          勤怠管理システムを自作することは、ランニングコストや自社に必要な機能のみに特化できるなどのメリットがあります。
          <br />
          しかし、改ざんが容易、法改正への対応ができないなど、事業に損害を与えかねないデメリットもあります。
          <br />
          そのため、勤怠管理システムを積極的に活用することが必要です。
          <br />
          <br />
          中小企業や小規模事業者にも適している勤怠管理システムに「キンクラ」があります。
          <br />
          キンクラには、法改正に対応しているだけではなく、自社に必要な機能がカスタマイズできアプリでの利用も可能です。勤怠管理システムを導入することで、事業を発生しうる損害から守ります。
          <br />
          ぜひこの機会に、キンクラをご検討ください。
        </p>
        <a href={routeParam6} className="column-detail-link">
          ⇒ 勤怠管理システム「キンクラ」について詳しく知りたい方はここをクリック
        </a>
      </section>
    </Wrapper>
  )
}

export default ColumnDetailInner0097
