import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 60px;
  border: solid 2px #e5e5e5;
  border-radius: 5px;
  padding: 20px;

  section {
    display: flex;
    justify-content: left;
    align-items: center;

    img {
      width: 110px;
      height: 110px;
      margin: 0 20px 0 0;
    }

    .author-wrap {
      position: relative;
      width: 100%;

      p {
        line-height: 2;

        &.author-name {
          font-size: 18px;
          font-weight: 600;

          span {
            font-weight: 400;
            font-size: 14px;
          }
        }

        &.author-job {
          position: relative;
          width: 100%;
          margin-left: 14px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 10px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }

          a {
            margin-right: 15px;
          }
        }
      }
    }
  }

  .detail-txt {
    border-top: solid 1px #dcdee1;
    margin-top: 20px;
    padding-top: 15px;
    line-height: 1.8;
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：オーサ情報 キンクラコラム編集部
 * @constructor
 */
const columnAuthor005 = () => (
  <Wrapper>
    <section>
      <img src="/images/column/authors/a005.png" alt="キンクラコラム編集部" />
      <div className="author-wrap">
        <p className="author-name">キンクラコラム編集部</p>
      </div>
    </section>
    <div className="detail-txt">
      キンクラコラム編集部です！このコラムでは、効率的な業務運営や経営判断のサポートを目指し
      <br />
      勤怠管理や経理に関する役立つ情報をわかりやすくお伝えします！
    </div>
  </Wrapper>
)

export default columnAuthor005
